$(document).ready(function () {
    const main_menu = $("#main-menu"),
        nav_mobile_overlay = $("#nav-mobile-overlay"),
        toggle_menu_btn = $("#toggle-menu");

    function determine_scroll_down(curr) {
        if ($(curr).scrollTop() > 50) {
            main_menu.addClass("scrolled-down")
        } else {
            main_menu.removeClass("scrolled-down")
        }
    }

    determine_scroll_down(this);

    $(window).on("load scroll", function () {
        determine_scroll_down(this);
    });

    toggle_menu_btn.add(nav_mobile_overlay).click(function () {
        if (main_menu.hasClass("open")) {
            main_menu.removeClass("open");
            nav_mobile_overlay.removeClass("active");
            $("html, body").css("overflow", "");
            toggle_menu_btn.find("i").addClass("fa-bars")
            toggle_menu_btn.find("i").removeClass("fa-times")
        } else {
            main_menu.addClass("open");
            nav_mobile_overlay.addClass("active");
            $("html, body").css("overflow", "hidden");
            toggle_menu_btn.find("i").removeClass("fa-bars")
            toggle_menu_btn.find("i").addClass("fa-times")
        }
    });
});

$(function () {
    $('.fancybox-img').fancybox({
        buttons: [
            'close'
        ],
        smallBtn: false,
        idleTime: false,
        animationEffect: "zoom",
        transitionEffect: "zoom",
        preload: true,
        arrows: true,
        lang: "nl",
        i18n: {
            en: {
                CLOSE: "Close",
                NEXT: "Next",
                PREV: "Previous",
                ERROR: "The requested content cannot be loaded. <br/> Please, try again later.",
                PLAY_START: "Start slideshow",
                PLAY_STOP: "Pause slideshow",
                FULL_SCREEN: "Full screen",
                THUMBS: "Thumbnails",
                DOWNLOAD: "Download",
                SHARE: "Share",
                ZOOM: "Zoom"
            },
            nl: {
                CLOSE: "Sluiten",
                NEXT: "Volgende",
                PREV: "Vorige",
                ERROR: "De opgevraagde afbeelding kon niet geladen worden. Onze excuses!<br/>Probeer het later opnieuw.",
                PLAY_START: "Start slideshow",
                PLAY_STOP: "Pauzeer slideshow",
                FULL_SCREEN: "Volledig scherm",
                THUMBS: "Thumbnails",
                DOWNLOAD: "Download",
                SHARE: "Delen",
                ZOOM: "In- en uitzoomen"
            }
        }
    });
});

$('[data-fancybox="gallery-image-item"]').fancybox({
    buttons: [
        'close'
    ],
    idleTime: false,
    /*beforeShow: function () {
        var instance = $.fancybox.getInstance();
        instance.$refs.infobar.text('');
        //instance.$refs.infobar.append('<span data-fancybox-index></span> van <span data-fancybox-count></span> ');
        instance.$refs.infobar.append('<a class="go-to-contact" href="/contact"><i class="fal fa-envelope-open mr-2"></i> Ja, zoiets wil ik ook</a>');
    },*/
});

$.fancybox.defaults.i18n.nl = {
    CLOSE: "Sluiten",
    NEXT: "Volgende",
    PREV: "Vorige",
    ERROR: "De opgevraagde afbeelding kon niet geladen worden. Onze excuses!<br/>Probeer het later opnieuw.",
    PLAY_START: "Start slideshow",
    PLAY_STOP: "Pauzeer slideshow",
    FULL_SCREEN: "Volledig scherm",
    THUMBS: "Thumbnails",
    DOWNLOAD: "Download",
    SHARE: "Delen",
    ZOOM: "In- en uitzoomen"
};

$.fancybox.defaults.lang = 'nl';